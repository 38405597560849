import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="vorsorge" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Vorsorge / Prävention`}</h1>
      <h3 {...{
        "id": "vorsorge--pravention"
      }}>{`Vorsorge / Prävention`}</h3>
      <p>{`Anti-Aging: gegen vorzeitiges Altern`}</p>
      <p>{`Ursachen gibt es viele: Stress, Fehlernährung, Schadstoffe der Umwelt, Genussgifte, Medikamente, Bewegungsmangel…`}</p>
      <p>{`Wir alle werden älter, diese Tatsache wird sich nicht umkehren lassen. Aber das „Wie“, die Qualität des Alterns können wir beeinflussen. Und es gibt Maßnahmen gegen vorzeitiges Altern!`}</p>
      <p>{`Unsere Praxis bietet ein umfangreiches Spektrum zum Thema gesundes Altern und Präventivmedizin.`}</p>
      <h3 {...{
        "id": "diagnostik"
      }}>{`Diagnostik`}</h3>
      <ul>
        <li parentName="ul">{`Gesundheitsuntersuchung: Belastbarkeit,Vitalkapazität`}</li>
        <li parentName="ul">{`komplettes klinisches Labor: Immunstatus, oxidativer Stress…`}</li>
        <li parentName="ul">{`Gesundheits-Check Darm: Stuhldiagnostik, Flora; Mykosen`}</li>
        <li parentName="ul">{`Haarmineralanalyse: toxische Belastungen, Mangelzustände`}</li>
        <li parentName="ul">{`Bodyimpedanzmessung: Muskel/Knochen, Fett, Wasser`}</li>
        <li parentName="ul">{`Irisdiagnose: genetische Belastung, konstitutionelle Schwächen`}</li>
      </ul>
      <h3 {...{
        "id": "therapie-optionen"
      }}>{`Therapie-Optionen`}</h3>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/darmsanierung"
          }}>{`Darmsanierung`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/entgiftung"
          }}>{`Entgiftung`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/immunstaerkung"
          }}>{`Immunstärkung`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/eigenblutbehandlung"
          }}>{`Eigenblutbehandlung`}</a></li>
        <li parentName="ul">{`homöopathische Hormonstimulation`}</li>
        <li parentName="ul">{`Stoffwechselaktivierung`}</li>
      </ul>
      <p><strong parentName="p">{`Gesund und vital bis ins hohe Alter !!!`}</strong></p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      